import React from "react";
import {Text} from "@consta/uikit/Text";
import {Badge} from "@consta/uikit/Badge";
import {useDispatch, useSelector} from "react-redux";
import {
    selectIsDecideAnswer,
    selectIsReturnedAnswer,
    selectAnswerData,
    selectUser
} from "../../utils/features/selectors";
import {setIsDecide, setIsReturned} from "../../utils/features/answerSlice";
import {fetchCancelReturnAnswer, fetchDeleteDecidedPhoto} from "../../utils/service/fetch.endpoint";
import {setIsAnswerSidebarOpen} from "../../utils/features/sidebarsOpenSlice";
import {showSnackBar} from "../../utils/features/snackBarSlice";

interface AnswerHeaderProps {
    viewType: string;
    handleHeaderAssignClick: () => void;
    handleCancelDecideClick: () => void;
    handleConfirmClick: () => void;
    clickReturn?: boolean;
}

const AnswerHeader: React.FC<AnswerHeaderProps> = ({
                                                       viewType,
                                                       handleHeaderAssignClick,
                                                       handleCancelDecideClick,
                                                       handleConfirmClick,
                                                   }) => {
    const user = useSelector(selectUser);
    const dispatch = useDispatch()
    const isDecide = useSelector(selectIsDecideAnswer)
    const isReturn = useSelector(selectIsReturnedAnswer)
    const selectedAnswerData = useSelector(selectAnswerData)

    const handleCancelReturn = async () => {
        if (!selectedAnswerData) {
            return
        }
        await fetchCancelReturnAnswer({answerID: selectedAnswerData.answer_id})
        dispatch(setIsAnswerSidebarOpen())
        dispatch(showSnackBar({
            messageText: 'Вернули в не распределенные!',
            status: 'success',
        }));

    }

    return (
        <div className="header_main_div">
            <Text className="answer_detail_header" size="xl" transform="uppercase" weight="bold">
                Кустовая площадка №{selectedAnswerData?.watch_place}
            </Text>

            {user?.isContract && viewType === "report" && (
                <Badge
                    status="success"
                    label="Взять в работу"
                    style={{marginRight: "10px", cursor: "pointer"}}
                    className="answer_icon_add_header"
                    onClick={handleHeaderAssignClick}
                />
            )}

            {viewType === "responsible_cabinet" && !isDecide && !selectedAnswerData?.decided_date && (
                <>
                    <Badge
                        status="warning"
                        label="Отменить"
                        style={{marginRight: "10px", cursor: "pointer"}}
                        onClick={handleCancelReturn}
                    />
                    <Badge
                        status="success"
                        label="Устранить"
                        style={{marginRight: "10px", cursor: "pointer"}}
                        className="answer_icon_add_header"
                        onClick={() => dispatch(setIsDecide(true))}
                    />
                </>
            )}

            {isDecide && (
                <Badge
                    status="error"
                    label="Отменить"
                    style={{marginRight: "10px", cursor: "pointer"}}
                    className="answer_icon_cancel_header"
                    onClick={handleCancelDecideClick}
                />
            )}

            {selectedAnswerData?.is_confirmed === null &&
                !isReturn &&
                !user?.isContract &&
                viewType === "report" &&
                selectedAnswerData?.is_decided && (
                    <>
                        <Badge
                            status="error"
                            label="Отклонить"
                            style={{marginRight: "10px", cursor: "pointer"}}
                            onClick={() => dispatch(setIsReturned(true))}
                        />
                        <Badge
                            status="success"
                            label="Принять"
                            style={{marginRight: "10px", cursor: "pointer"}}
                            onClick={handleConfirmClick}
                        />
                    </>
                )}
        </div>
    );
};

export default AnswerHeader;
