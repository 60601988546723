import {fetchUpdateChecklist} from "../service/fetch.endpoint";
import {CheckedNullCountAnswer} from "../../pages/new/component/helpers/checkedNullCountAnswer";
import {BuildSaveData} from "../../pages/new/component/helpers/buildSaveData";
import {
    clearChecklistState,
} from "../features/checklistSlice";
import {TemplateListDataType, ViewModeType} from "../../types/new.type";
import {showSnackBar} from "../features/snackBarSlice";
import {setIsChecklistSidebarOpen, setNullModalOpen} from "../features/sidebarsOpenSlice";
import {setRefetchGrid} from "../features/gridSlice";
import {ComboboxType} from "../../core.component/mainInfo.constructor";

interface SaveButtonPropsInterface {
    checklistID: string;
    viewMode: ViewModeType;
    valueAnswer: any;
    valueDate: Date | null;
    valueMainInfo: any;
    mainMenuElements: ComboboxType[];
    currentTemplate?: TemplateListDataType;
    savedChecklistData: any;
    newTemplateData?: TemplateListDataType;
    dispatch: (action: any) => void; // Теперь используем dispatch
    username: string | undefined;
}

export const handleSaveButton = async (props: SaveButtonPropsInterface) => {
    const {
        checklistID,
        viewMode,
        valueAnswer,
        valueDate,
        valueMainInfo,
        mainMenuElements,
        currentTemplate,
        savedChecklistData,
        dispatch,
        username
    } = props;

    const nullCount = CheckedNullCountAnswer(valueAnswer);

    if (nullCount > 0 || !valueDate || !valueMainInfo?.[0] || !valueMainInfo?.[1]) {
        dispatch(setNullModalOpen());
    } else if (valueMainInfo) {
        const mainInfo = mainMenuElements;
        // let updatedTemplate = currentTemplate;

        // Клонируем currentTemplate и заменяем id, если это не новый шаблон
        // if (viewMode !== 'new' && currentTemplate?.template_id != null) {
        //     updatedTemplate = {
        //         ...currentTemplate, // Клонируем объект
        //         id: currentTemplate.template_id // Заменяем поле id
        //     };
        // }

        // Формируем данные для сохранения
        const saveData = BuildSaveData({
            mainInfo,
            valueAnswer,
            valueMainInfo,
            checklistID,
            currentTemplate,
            savedChecklistData,
            valueDate,
            username
        });

        // Отправляем данные на сервер
        const saveResult = await fetchUpdateChecklist({
            saveData: saveData,
            isDraft: saveData.answer.length === 0
        });

        if (saveResult?.status === 200) {
            dispatch(clearChecklistState())
            dispatch(setRefetchGrid())
            dispatch(setIsChecklistSidebarOpen(false));
            dispatch(showSnackBar({
                messageText: 'Данные успешно сохранены!',
                status: 'success',
            }));
        }
    }
};

