import React from 'react';
import '../../pages/report/component/css/doughnut.legend.css'
import {Text} from '@consta/uikit/Text';

interface ChartLegendProps {
    labels: string[];
    colors: string[];
}

const ChartLegend: React.FC<ChartLegendProps> = ({labels, colors}) => {
    return (
        <div className="chart-legend-container">
            <div className="chart-legend">
                <ul>
                    {labels.map((label, index) => (
                        <li key={index}>
                            <div
                                className="legend-color"
                                style={{backgroundColor: colors[index]}}
                            ></div>
                            <Text lineHeight="2xs" size='xs'>{label}</Text>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default ChartLegend;