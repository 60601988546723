import React, {useCallback, useMemo, useState, useEffect, useRef} from "react";
import {AgGridReact} from "@ag-grid-community/react";
import "@ag-grid-community/styles/ag-grid.css";
import "@ag-grid-community/styles/ag-theme-alpine.css";
import moment from "moment";
import {SetFilterModule} from "@ag-grid-enterprise/set-filter";
import {fetchChecklistData, fetchDeleteChecklist,} from "../../utils/service/fetch.endpoint";
import '../../styles/grid.journal.css';
import {CellClickedEvent, ModuleRegistry} from "@ag-grid-community/core";
import {useDispatch} from "react-redux";
import {statusPanel} from "../../pages/report/component/helpers/grid.report.settings";
import {JournalTemplateProps} from "../../templates/journal.template";
import {customEngLocale} from "../../core.component/gridLocale";
import {useSelector} from "react-redux";
import {setChecklistID} from "../../utils/features/checklistSlice";
import {useQuery} from "@tanstack/react-query";
import {selectRefetchGrid, selectUser} from "../../utils/features/selectors";
import {IconHelmet} from '@consta/icons/IconHelmet';
import {IconRing} from '@consta/icons/IconRing';
import {IconWatchFilled} from '@consta/icons/IconWatchFilled';
import {IconQuestion} from '@consta/icons/IconQuestion';
import {IconCheck} from '@consta/icons/IconCheck';


ModuleRegistry.registerModules([SetFilterModule]);

interface AgGridTableProps extends JournalTemplateProps {
    onOpenSidebar: (id: string, template: any) => void;
}

const AgGridTable: React.FC<AgGridTableProps> = ({onOpenSidebar, fetchType}) => {
    const dispatch = useDispatch();
    const user = useSelector(selectUser);
    const refetchGrid = useSelector(selectRefetchGrid);

    const gridRef = useRef<any>();
    const [rowData, setRowData] = useState<any>();
    const [isLoading, setIsLoading] = useState(false);


    const {data: checklistData, refetch} = useQuery({
        queryKey: ['checklistData'],
        queryFn: async () => {
            const fetchResult = await fetchChecklistData(user?.userRole ?? '', {fetchType})
            setRowData(fetchResult)
            return fetchResult
        },
        enabled: !!user?.userRole
    });

    const statusBar = useMemo(() => {
        return statusPanel
    }, []);

    const gridOptions = {
        localeText: customEngLocale
    };

    const columnDefs = useMemo(() => [
        {field: "id", headerName: "id", hide: true},
        {
            field: "status",
            headerName: "",
            filter: true,
            width: 60, // Фиксированная ширина для колонки 'status'
            valueGetter: (params: any) => {
                const answerList = params.data?.answer_list;
                if (!answerList) return "Устранено"; // Проверка на undefined

                // Вернуть текстовый статус для фильтрации
                if (answerList.some((answer: any) => answer.is_decided)) {
                    return "На проверку";
                }
                if (!answerList.some((answer: any) => answer.assigned_id !== "None")) {
                    return "Не распределено";
                }
                const allAssigned = answerList.every((answer: any) =>
                    answer.assigned_id !== "None" &&
                    answer.is_decided === false &&
                    answer.is_confirmed === "None" &&
                    answer.is_returned === false
                );
                if (allAssigned) {
                    return "В работе";
                }
                return "Другой статус";
            },
            cellRenderer: (params: any) => {
                const answerList = params.data?.answer_list;

                const iconStyles = {
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100%' // Занять всю высоту ячейки
                };

                // Иконка "Устранено"
                if (!answerList) {
                    return (
                        <span title="Устранено" style={iconStyles}>
                        <IconCheck style={{color: 'green'}}/>
                    </span>
                    );
                }


                // Иконка "На проверку"
                if (answerList.some((answer: any) => answer.is_decided)) {
                    return (
                        <span title="На проверку" style={iconStyles}>
                        <IconRing style={{color: '#0077d0'}}/>
                    </span>
                    );
                }

                // Иконка "Не распределено"
                if (!answerList.some((answer: any) => answer.assigned_id !== "None")) {
                    return (
                        <span title="Не распределено" style={iconStyles}>
                        <IconWatchFilled style={{color: 'gray'}}/>
                    </span>
                    );
                }

                // Иконка "В работе"
                const allAssigned = answerList.every((answer: any) =>
                    answer.assigned_id !== "None" &&
                    answer.is_decided === false &&
                    answer.is_confirmed === "None" &&
                    answer.is_returned === false
                );
                if (allAssigned) {
                    return (
                        <span title="В работе" style={iconStyles}>
                        <IconHelmet style={{color: 'orange'}}/>
                    </span>
                    );
                }


                // Иконка для неизвестного или другого статуса
                return (
                    <span title="Другой статус" style={iconStyles}>
                    <IconQuestion style={{color: 'gray'}}/>
                </span>
                );

            },
        },
        {
            field: "watch_date",
            headerName: "Дата",
            filter: true,
            valueFormatter: (params: any) => moment(params.value).format("DD.MM.YYYY"),
            width: 120
        },
        {field: "deposit", headerName: "Месторождение", hide: false, filter: true, enableRowGroup: true, flex: 1},
        {field: "watch_place", headerName: "КП", width: 75, filter: true, enableRowGroup: true,},
        {field: "author", headerName: "Составил", hide: true, filter: true, enableRowGroup: true, flex: 1},
    ], []);


    const defaultColDef = useMemo(() => ({
        sortable: true,
        resizable: true,
        // flex: 1,
        enablePivot: true,
    }), []);


    const onGridReady = useCallback(async () => {
        refetch()
        setIsLoading(false);
    }, [fetchType, user?.userRole]);

    useEffect(() => {
        setIsLoading(true);
        onGridReady();
    }, [onGridReady, fetchType, refetchGrid]);

    const getContextMenuItems = useCallback((params: any) => {
        let result = [
            {
                name: "Посмотреть",
                action: async () => {
                    onOpenSidebar(params.node.data.id, {
                        id: params.node.data.id,
                        name: params.node.data.template,
                        template_id: params.node.data.template_id,
                        rowData: rowData
                    });
                },
                icon: '<img src="/icon/view1cm.png" alt="View"/>'
            },
            params.node.data.is_draft && {
                name: "Изменить",
                action: async () => {
                    onOpenSidebar(params.node.data.id, {
                        id: params.node.data.id,
                        name: params.node.data.template,
                        template_id: params.node.data.template_id
                    });
                },
                icon: '<img src="/icon/edit1cm.png" alt="Edit"/>'
            },
            "separator",
            {
                name: "Удалить",
                action: async () => {
                    const deleteStatus = await fetchDeleteChecklist(params.node.data.id);
                    if (deleteStatus.status === 200) onGridReady();
                },
                icon: '<img src="/icon/delete1cm.png" alt="Delete"/>'
            }
        ];
        return result;
    }, [onOpenSidebar, onGridReady]);

    const getRowId = useMemo(() => (params: any) => params.data.id, []);


    const onCellClicked = (event: CellClickedEvent) => {
        if (event.event?.type === 'click' && event.data?.id) {
            dispatch(setChecklistID(event.data.id));
            // todo loacation draft если из черновика
            onOpenSidebar(event.data.id, {
                id: event.data.id,
                name: event.data.template,
                template_id: event.data.template_id,
                rowData: rowData
            });
        }
    };


    return (
        <div className="journal-grid ag-theme-alpine gridSlave">
            {isLoading ? "LOADING" : (
                <AgGridReact
                    gridOptions={gridOptions}
                    ref={gridRef}
                    rowData={rowData}
                    columnDefs={columnDefs}
                    defaultColDef={defaultColDef}
                    getRowId={getRowId}
                    getContextMenuItems={getContextMenuItems}
                    statusBar={statusBar}
                    rowGroupPanelShow={'always'}
                    onCellClicked={onCellClicked}
                />
            )}
        </div>
    );
};


export default AgGridTable;
