import React, {useEffect, useState} from "react";
import {Collapse} from "@consta/uikit/Collapse";
import {Button} from "@consta/uikit/Button";
import PhotoCarousel from "./photoCarousel";
import {IconCamera} from '@consta/icons/IconCamera';
import {API_REGISTRY_UPLOAD_PHOTO} from "../utils/service/api.endpoint";
import {fetchDeletePhoto} from "../utils/service/fetch.endpoint";
import {axiosInstance} from "../utils/service/axiosConfig";
import LoadingOverlay from "./loadingOverlay";
import {ViewModeType} from "../types/new.type";
import {useSelector} from "react-redux";
import {selectChecklistID,} from "../utils/features/selectors";

export type SaveMethodType = 'new' | 'decided'

const PhotoCollapse: React.FC<{
    index: number;
    answerID: string;
    photo: string[];
    thumbnail: string[];
    saveMethod: SaveMethodType;
    canDelete?: boolean;
    viewMode?: ViewModeType;

}> = ({
          index, answerID, photo, thumbnail, saveMethod, canDelete, viewMode,
      }) => {
    const checklistID = useSelector(selectChecklistID);
    const [newChecklistAnswerThumb, setNewChecklistAnswerThumb] = useState<string[]>(photo ?? [])
    const [newChecklistAnswerPhoto, setNewChecklistAnswerPhoto] = useState<string[]>(thumbnail ?? [])
    const [isOpen, setIsOpen] = useState<boolean>(photo.length > 0 || newChecklistAnswerThumb.length > 0);
    const [isUploading, setIsUploading] = useState(false);  // Состояние для управления загрузкой
    const [uploadProgress, setUploadProgress] = useState(0); // Состояние для отслеживания прогресса загрузки

    useEffect(() => {
        thumbnail.length > 0 && setIsOpen(true);
        newChecklistAnswerThumb.length > 0 && setIsOpen(true);
    }, [thumbnail, newChecklistAnswerThumb]);

    useEffect(() => {
        setNewChecklistAnswerThumb(thumbnail);
        setNewChecklistAnswerPhoto(photo);
    }, [thumbnail, photo]);

    const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            try {
                setIsUploading(true); // Начало загрузки
                const formData = new FormData();
                formData.append("photo_file", file);
                formData.append("checklist_id", checklistID);
                formData.append("answer_id", answerID);
                formData.append("save_method", saveMethod);

                const response = await axiosInstance.post(API_REGISTRY_UPLOAD_PHOTO, formData, {
                    onUploadProgress: progressEvent => {
                        if (progressEvent.total) {
                            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                            setUploadProgress(percentCompleted);
                        }
                    }
                });

                if (response.data.success) {
                    setNewChecklistAnswerPhoto([...newChecklistAnswerPhoto, response.data.photo_path,])
                    setNewChecklistAnswerThumb([...newChecklistAnswerThumb, response.data.thumbnail])

                } else {
                    console.error("Ошибка при сохранении изображения:", response.data);
                }

            } catch (error) {
                console.error("Ошибка при отправке запроса:", error);
            } finally {
                setIsUploading(false); // Конец загрузки
                setUploadProgress(0); // Сброс прогресса
            }
        }
    };

    const handleDeletePhoto = async (photoPath: string) => {
        const uuidMatch = photoPath.match(/[0-9a-fA-F-]{36}/);
        const photoID = uuidMatch ? uuidMatch[0] : 'null';

        try {
            const result = await fetchDeletePhoto({photoID});

            if (result.status === 200) {
                if (newChecklistAnswerPhoto.length > 0) {
                    setNewChecklistAnswerPhoto(newChecklistAnswerPhoto.filter((_, i) => i !== index))
                    setNewChecklistAnswerThumb(newChecklistAnswerThumb.filter((_, i) => i !== index))
                }
            }

        } catch (error) {
            console.error('Ошибка при удалении фото:', error);
        }
    };

    return (
        <>
            <Collapse
                label="Фото"
                isOpen={isOpen}
                onClick={() => setIsOpen((prev: any) => !prev)}
                rightSide={
                    <React.Fragment>
                        {viewMode !== 'view' && <Button
                            iconLeft={IconCamera}
                            size="s"
                            view="ghost"
                            onClick={() => document.getElementById(`fileInput-${index}`)?.click()}
                        />}
                        <input
                            id={`fileInput-${index}`}
                            type="file"
                            accept="image/*"
                            style={{display: 'none'}}
                            onChange={handleFileChange}
                        />
                    </React.Fragment>
                }>
                <section>
                    <PhotoCarousel cameraPhotos={newChecklistAnswerPhoto}
                                   thumbnailPhotos={newChecklistAnswerThumb}
                                   onDeletePhoto={handleDeletePhoto}
                                   canDelete={canDelete}/>
                </section>
            </Collapse>
            {isUploading && <LoadingOverlay progress={uploadProgress}/>} {/* Отображение прогресса загрузки */}
        </>
    )
}

export default PhotoCollapse;
