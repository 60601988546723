import {Dispatch} from "redux";
import moment from "moment";
import {setValueMainInfo, setValueDate} from "../features/checklistSlice";
import {getMainInfoDataFromSavedData} from "../helpers/getMainInfoDataFromSavedData";
import {ViewModeType} from "../../types/new.type";
import {MainInfoDict} from "../service/config";
import {ComboboxType} from "../../core.component/mainInfo.constructor";

interface BuildMainInfoDataProps {
    valueMainInfo: any;
    viewMode: ViewModeType;
    currentTemplate?: any;
    checklistData?: any;
    dispatch: Dispatch<any>;
    setMainMenuElements: (elements: ComboboxType[]) => void;
}

export const buildMainInfoData = (props: BuildMainInfoDataProps) => {
    const {valueMainInfo, viewMode, currentTemplate, checklistData, dispatch} = props;

    if (viewMode === 'new' && currentTemplate) {
        const mainInfo = currentTemplate?.main_info;
        if (mainInfo) {
            const mainInfoKeys = Object.keys(mainInfo) as (keyof typeof mainInfo)[];
            const result = mainInfoKeys
                .filter(key => mainInfo[key])
                .map((key: any) => MainInfoDict[key]);

            // dispatch(setMainMenuElements(result));
            props.setMainMenuElements(result)
            if (!valueMainInfo) {
                dispatch(setValueMainInfo(result.map(() => null)));
            }
            return result;
        }
    } else if (viewMode !== 'new' && checklistData) {
        const {main_info} = checklistData;
        const newDate = new Date(moment(main_info.watch_date).format('YYYY-MM-DDTHH:mm:ss.SSSZ'))
        dispatch(dispatch(setValueDate(newDate ? newDate.toISOString() : null)));

        const result = getMainInfoDataFromSavedData(Object.keys(MainInfoDict), main_info);
        props.setMainMenuElements(result);
        // dispatch(setMainMenuElements(result));

        return result;
    }
};
