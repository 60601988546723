import React, {useState, useEffect} from 'react';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import '../pages/new/component/css/carousel.style.css';

function LazyLoader({src, delay = 0, onLoad, onClick, onDelete, canDelete}: any) {
    let timerId: any;
    const [isMounted, setMounted] = useState(false);
    const [isLoading, setLoading] = useState(true);

    function loadImage() {
        const image = new Image();
        image.src = src;
        image.onload = () => {
            setLoading(false);
            onLoad();
        };
        image.onerror = () => {
            setLoading(false);
        };
    }

    useEffect(() => {
        if (!isMounted) {
            setMounted(true);
            delay ? (timerId = setTimeout(loadImage, delay)) : loadImage();
        }
        return () => clearTimeout(timerId);
    }, []);

    return isLoading ? (
        <div>Loading...</div>
    ) : (
        <div className="image-container" onClick={onClick}>
            <img className="carousel-image" src={src}/>
            {canDelete && <button className="delete-button" onClick={(e) => {
                e.stopPropagation();
                onDelete(src);
            }}>Удалить</button>}
        </div>
    );
}

const Modal = ({src, onClose}: any) => {
    return (
        <div className="modal" onClick={onClose}>
            <span className="close">&times;</span>
            <img className="modal-content" src={src}/>
        </div>
    );
}

interface PhotoCarouselInterface {
    cameraPhotos: string[],
    thumbnailPhotos: string[],
    onDeletePhoto?: any,
    canDelete?: any
}

const PhotoCarousel: React.FC<PhotoCarouselInterface> = (props) => {
    const [, setTimestamp] = useState(0);
    const [activeIndex, setActiveIndex] = useState(0);
    const [modalPhoto, setModalPhoto] = useState<string | null>(null);

    const onLoad = () => setTimestamp(Date.now());
    const onSlideChanged = ({item}: any) => setActiveIndex(item);
    const src = (name = '') => `${name}`;
    const handleDelete = (photoPath: string) => {
        props.onDeletePhoto(photoPath);
    }

    let items: any[] = [];

    if (props.thumbnailPhotos.length > 0) {
        items = props.thumbnailPhotos.map((photoPath: any, index: number) => (
            <LazyLoader
                key={photoPath}
                onLoad={onLoad}
                src={src(photoPath)}
                onClick={() => setModalPhoto(props.cameraPhotos[index])}
                onDelete={handleDelete}
                canDelete={props.canDelete}
            />
        ));
    }

    return (
        <>
            <AliceCarousel
                animationType="fadeout"
                animationDuration={800}
                disableButtonsControls
                keyboardNavigation
                autoHeight
                autoWidth
                mouseTracking
                activeIndex={activeIndex}
                onSlideChanged={onSlideChanged}
                items={items}
            />
            {modalPhoto && <Modal src={modalPhoto} onClose={() => setModalPhoto(null)}/>}
        </>
    );
};

export default PhotoCarousel;