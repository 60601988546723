import {useQuery} from '@tanstack/react-query';

import {fetchReportData, fetchResponsibleData} from "../utils/service/fetch.endpoint";
import {AnswerInterface} from "../pages/responsible/page.remark";
import {ReportDataInterface} from "../pages/report/page.report.bypass";

export interface FetchDataResponsibleInterface {
    bypass_data: {
        assigned: AnswerInterface[];
        waiting: AnswerInterface[];
        returned: AnswerInterface[];
        decided: AnswerInterface[];
    },
    karkas_data: {
        assigned: AnswerInterface[];
    }
}

// Хук для получения данных ответственных
export const useResponsibleData = (isContract: boolean) => {
    return useQuery<FetchDataResponsibleInterface>({
        queryKey: ['fetchData'],
        queryFn: fetchResponsibleData,
        enabled: !!isContract, // запрос будет выполняться только при наличии контракта
        staleTime: 60000, // 1 минут
    });
};

// Хук для получения данных отчета
export const useReportData = (isContract: boolean) => {
    return useQuery<ReportDataInterface[]>({
        queryKey: ['reportData'],
        queryFn: () => fetchReportData({checklistTemplate: 'bypass'}),
        enabled: !!isContract, // запрос будет выполняться только при наличии контракта
        staleTime: 60000, // 1 минут
    });
};
