import {createSelector} from 'reselect';
import {RootState} from "../service/reduxStore";

const selectUrlPathState = (state: RootState) => state.url;

const selectNotificationState = (state: RootState) => state.notification;

const selectGridState = (state: RootState) => state.grid;

const selectAnswerState = (state: RootState) => state.answer;

const selectSidebarsOpenState = (state: RootState) => state.sidebarsOpen;

// Селектор для получения всего состояния checklist
const selectChecklistState = (state: RootState) => state.checklist;
const selectToBackendState = (state: RootState) => state.toBackend;

// Селекторы для состояния user
export const selectUser = (state: RootState) => state.user.user;

// Мемоизированные селекторы для состояния checklist
export const selectQuestionData = createSelector(
    selectChecklistState,
    (checklist) => checklist.questionData
);


export const selectValueMainInfo = createSelector(
    selectChecklistState,
    (checklist) => checklist.valueMainInfo
);

export const selectValueDate = createSelector(
    selectChecklistState,
    (checklist) => (checklist.valueDate ? new Date(checklist.valueDate) : null)
);

export const selectViewMode = createSelector(
    selectChecklistState,
    (checklist) => checklist.viewMode
);

export const selectChecklistID = createSelector(
    selectChecklistState,
    (checklist) => checklist.checklistID
);

export const selectCurrentTemplate = createSelector(
    selectChecklistState,
    (checklist) => checklist.selectedTemplate
);


export const selectSelectedTemplate = createSelector(
    selectChecklistState,
    (checklist) => checklist.selectedTemplate
);

export const selectIsNullCountModalOpen = createSelector(
    selectSidebarsOpenState,
    (sidebarsOpen) => sidebarsOpen.isNullCountModalOpen
);

export const selectUrlPath = createSelector(
    selectUrlPathState,
    (url) => url.path
);

export const selectIsChecklistSidebarOpen = createSelector(
    selectSidebarsOpenState,
    (sidebarsOpen) => sidebarsOpen.isChecklistSidebarOpen
);
export const selectIsAnswerSidebarOpen = createSelector(
    selectSidebarsOpenState,
    (sidebarsOpen) => sidebarsOpen.isAnswerSidebarOpen
);
export const selectRefetchGrid = createSelector(
    selectGridState,
    (grid) => grid.refetchGrid
);
export const selectIsDecideAnswer = createSelector(
    selectAnswerState,
    (answer) => answer.isDecide
);

export const selectIsReturnedAnswer = createSelector(
    selectAnswerState,
    (answer) => answer.isReturned
);

export const selectReturnComment = createSelector(
    selectAnswerState,
    (answer) => answer.returnComment
);

export const selectDecideComment = createSelector(
    selectAnswerState,
    (answer) => answer.decideComment
);

export const selectAnswerData = createSelector(
    selectAnswerState,
    (answer) => answer.selectedAnswerData
);

export const selectToBackendAnswerData = createSelector(
    selectToBackendState,
    (toBackend) => toBackend.valueAnswerData
);

export const selectNotifications = createSelector(
    selectNotificationState,
    (notification) => notification.items
);

