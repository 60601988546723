import React from 'react';
import {Sidebar} from "@consta/uikit/Sidebar";
import {Grid, GridItem} from "@consta/uikit/Grid";
import {Button} from "@consta/uikit/Button";
import AnswerSidebar from "./answerSidebar";
import {viewTypeType} from "../../pages/responsible/page.remark";
import {useDispatch, useSelector} from "react-redux";
import {
    selectDecideComment,
    selectIsAnswerSidebarOpen,
    selectIsDecideAnswer,
    selectIsReturnedAnswer, selectReturnComment, selectAnswerData, selectUser
} from "../../utils/features/selectors";
import {fetchUpdateDecidedAnswer} from "../../utils/service/fetch.endpoint";
import {setIsAnswerSidebarOpen} from "../../utils/features/sidebarsOpenSlice";
import {
    clearAnswerState,
} from "../../utils/features/answerSlice";
import {AnswerType, clearChecklistState} from "../../utils/features/checklistSlice";
import {showSnackBar} from "../../utils/features/snackBarSlice";

interface SidebarWrapperProps {
    index?: number;
    handleCloseSideBar: () => void;
    checklistTemplateType: string;
    viewType: viewTypeType;
    valueAnswer?: AnswerType[];
    setValueAnswer?: (value: any) => void;

}

const AnswerSidebarTemplate: React.FC<SidebarWrapperProps> = ({
                                                                  index,
                                                                  handleCloseSideBar,
                                                                  checklistTemplateType,
                                                                  viewType,
                                                                  valueAnswer,
                                                                  setValueAnswer,
                                                              }) => {
    const dispatch = useDispatch()
    const showSidebarAnswerDetail = useSelector(selectIsAnswerSidebarOpen)
    const isDecide = useSelector(selectIsDecideAnswer)
    const isReturn = useSelector(selectIsReturnedAnswer)
    const returnComment = useSelector(selectReturnComment)
    const decideComment = useSelector(selectDecideComment)
    const selectedAnswerData = useSelector(selectAnswerData)

    const updateIsAnswer = (is_returned: boolean, is_confirmed: boolean) => {
        // Проверяем, что индекс существует и больше или равен 0
        if (index === undefined || index === null || !valueAnswer || !setValueAnswer) {
            return;
        }

        // Создаем копию массива valueAnswer
        const updatedValueAnswer = [...valueAnswer];

        // Обновляем поле is_returned у элемента массива
        updatedValueAnswer[index] = {
            ...updatedValueAnswer[index],
            is_returned: is_returned,
            is_confirmed: is_confirmed
        };

        // Устанавливаем обновленное состояние
        setValueAnswer(updatedValueAnswer);
    };

    const handleSaveAndCloseSideBar = async () => {
        if (selectedAnswerData) {
            await fetchUpdateDecidedAnswer({
                answerID: selectedAnswerData.answer_id,
                decideComment: decideComment,
                returnComment: returnComment
            })
            dispatch(setIsAnswerSidebarOpen());
            decideComment && dispatch(showSnackBar({
                messageText: 'Направлено на проверку!',
                status: 'success',
            }));

            updateIsAnswer(!!returnComment, !!decideComment)
            // Очищаем комментарии и закрываем сайдбар
            dispatch(clearAnswerState());
            dispatch(clearChecklistState())
            setValueAnswer && setValueAnswer(null)
        }
    };

    return (
        <Sidebar
            isOpen={showSidebarAnswerDetail}
            onEsc={handleCloseSideBar}
            position='bottom'
            size={isDecide ? 'full' : '2/3'}
            className="sidebar"
        >
            <Sidebar.Content>
                {selectedAnswerData && checklistTemplateType === 'bypass' &&
                    <AnswerSidebar
                        selectedAnswerData={selectedAnswerData}
                        viewType={viewType}
                        updateIsAnswer={updateIsAnswer}
                    />}
            </Sidebar.Content>
            <Sidebar.Actions>
                <Grid cols={(isDecide || isReturn) ? 2 : 1}>
                    <GridItem col={1}>
                        <Button
                            size="m"
                            view="ghost"
                            label={(isDecide || isReturn) ? "Отменить" : "Закрыть"}
                            width="full"
                            onClick={handleCloseSideBar}
                        />
                    </GridItem>
                    {(isDecide || isReturn) &&
                        <GridItem col={1}>
                            <Button
                                size="m"
                                view="ghost"
                                label="Сохранить"
                                width="full"
                                onClick={handleSaveAndCloseSideBar}
                                disabled={!decideComment && !returnComment}
                            />
                        </GridItem>}
                </Grid>
            </Sidebar.Actions>
        </Sidebar>
    );
};

export default AnswerSidebarTemplate;
