import React, {useEffect, useMemo} from 'react';
import {BrowserRouter as Router, Route, useLocation, Routes, Navigate} from "react-router-dom";
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';
import {Theme, presetGpnDefault} from '@consta/uikit/Theme';
import "./pages/css/mainPage.css";
import {configureAgGrid} from "./utils/service/config";
import NewChecklistPage from "./pages/new/new.page";
import JournalPage from "./pages/journal/journal.page";
import PageReportBypass from "./pages/report/page.report.bypass";
import PageRemark from "./pages/responsible/page.remark";
import {AuthProvider, useAuth} from "./utils/wrapper/authContext";
import LoginPage from "./pages/login/login.page";
import TokenRefreshWrapper from "./utils/wrapper/tokenRefresh";
import JournalDraftPage from "./pages/journal_draft/journalDraft.page";
import HeaderOrganism from "./organisms/header/header.organism";
import {ReactQueryDevtools} from '@tanstack/react-query-devtools';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from "./utils/service/reduxStore";
import SnackBarComponent from "./core.component/snackBar";
import {setUrlPath} from "./utils/features/urlSlice";
import {selectUser} from "./utils/features/selectors";

// Настройка библиотек
configureAgGrid();

const queryClient = new QueryClient();

const App = () => {
    const urlPath = useSelector((state: RootState) => state.url.path);
    const {showSnackBar} = useSelector((state: RootState) => state.snackBar);

    useEffect(() => {
        document.title = 'Чеклист';
    }, []);

    const isAuthenticated = (accessToken: string | null) => {
        return !!accessToken;
    };

    const DynamicContent: React.FC<{ urlPath: string }> = ({urlPath}) => {
        const {accessToken} = useAuth();

        if (!isAuthenticated(accessToken)) {
            return <Navigate to="/" replace/>;
        }

        switch (urlPath) {
            case '/new':
                return <NewChecklistPage/>;
            case '/journal':
                return <JournalPage/>;
            case '/draft-journal':
                return <JournalDraftPage/>;
            case '/total_info_bypass':
                return <PageReportBypass/>;
            case '/remark':
                return <PageRemark/>;
            default:
                return <div>Page Not Found</div>;
        }

    };
    return (
        <Router>
            <AuthProvider>
                <TokenRefreshWrapper>
                    <QueryClientProvider client={queryClient}>
                        <Theme className="App" preset={presetGpnDefault}>
                            <ConditionalLayout>
                                <Routes>
                                    <Route path="/" element={<RootRedirect/>}/>
                                </Routes>
                                {urlPath && <DynamicContent urlPath={urlPath}/>}

                            </ConditionalLayout>
                            {showSnackBar && (
                                <SnackBarComponent/>
                            )}
                        </Theme>
                        <ReactQueryDevtools initialIsOpen={false}/>
                    </QueryClientProvider>
                </TokenRefreshWrapper>
            </AuthProvider>
        </Router>
    );
}


// условно рендерит Header и Footer
const ConditionalLayout: React.FC<{ children: React.ReactNode }> = ({children}) => {
    const location = useLocation();
    const dispatch = useDispatch();
    const user = useSelector(selectUser);
    const hideHeaderAndFooter = location.pathname.startsWith('/login');

    const violanteMenuItems = [{
        label: "Нарушения",
        onClick: () => dispatch(setUrlPath('/remark'))
    }];

    const inspectorMenuItems = useMemo(() => [
        {label: "Новый", onClick: () => dispatch(setUrlPath('/new'))},
        {label: "Мои проверки", onClick: () => dispatch(setUrlPath('/journal'))},
        {label: "Черновики", onClick: () => dispatch(setUrlPath('/draft-journal'))}
    ], []);

    const menuItems = user?.isContract ? violanteMenuItems : inspectorMenuItems

    return (
        <>
            {!hideHeaderAndFooter && user?.isContract !== undefined &&
                <HeaderOrganism menuItems={menuItems}/>}
            <main>{children}</main>
        </>
    );
};

const RootRedirect: React.FC = () => {
    const {accessToken} = useAuth();
    return accessToken ? <Navigate to="/" replace/> : <LoginPage/>;
};

export default App;
