import React, {useState, useEffect} from 'react';
import {Tabs} from '@consta/uikit/Tabs';


import {Card} from "@consta/uikit/Card";
import DoughnutChart from "./doughnut.report.cmp";
import {ReportDataInterface} from "../page.report.bypass";
import PointChart from "./pointChart.report.cmp";
import {ChecklistTemplateType} from "../../../utils/service/fetch.endpoint";
import {SaveMethodType} from "../../../organisms/photoCollapse";
import { IconLightningBolt } from '@consta/icons/IconLightningBolt';
import { IconThumbUp } from '@consta/icons/IconThumbUp';

// Типы данных для вкладок
type TabsDataType = 'new' | 'decided' | 'dynamic';

type Item = {
    name: string;
    image?: any;
    dataType: TabsDataType;
};

// Компоненты для иконок с заданным цветом
const RedLightningBoltIcon = () => <IconLightningBolt style={{color: 'red'}} />;
const GreenThumbUpIcon = () => <IconThumbUp style={{color: 'green'}} />;

const items: Item[] = [
    {
        name: "В работе",
        image: RedLightningBoltIcon,  // Иконка красного цвета
        dataType: 'new'
    },
    {
        name: "Завершено",
        image: GreenThumbUpIcon,  // Иконка зеленого цвета
        dataType: 'decided'
    },
];

const getItemIcon = (item: any) => item.image;

interface TabsReportInterface {
    dataType: SaveMethodType;
    setDataType: (dataType: SaveMethodType) => void;
    chartData: ReportDataInterface | null;
    setChartData: (chartData: any) => void;
    filterData: any;
    setFilterData: (filterData: any) => void;
    checklistTemplateType: ChecklistTemplateType;
}

const useChartUpdate = (chartData: any, dataType: SaveMethodType) => {
    const [chartState, setChartState] = useState(chartData[dataType]);

    useEffect(() => {
        setChartState(chartData[dataType]);
    }, [chartData, dataType]);

    return chartState;
};

const TabsReport = (props: TabsReportInterface) => {
    const [value, setValue] = useState<Item | null>(items[0]);

    const chartState = useChartUpdate(props.chartData, props.dataType);

    useEffect(() => {
        const currentItem = items.find((item) => item.dataType === props.dataType);
        if (currentItem) {
            setValue(currentItem);
        }
    }, [props.dataType]);

    const handleChangeTabs = (value: Item) => {
        setValue(value);
        if (value.dataType === 'dynamic') {
            props.setDataType('new');
            props.setFilterData(chartState);
        } else {
            props.setDataType(value.dataType);
        }
    };

    const handleChartClick = (filterData: any) => {
        props.setFilterData(filterData);
    };

    return (
        <>
            {props.checklistTemplateType === 'bypass' &&
                <Tabs
                    value={value}
                    onChange={(value) => handleChangeTabs(value)}
                    items={items}
                    getItemLabel={(item) => item.name}
                    getItemLeftIcon={getItemIcon}
                />
            }

            <Card style={{backgroundColor: 'white'}}>
                {value?.dataType !== 'dynamic' ?
                    <DoughnutChart
                        chartData={chartState}
                        setChartData={props.setChartData}
                        dataType={value?.dataType as SaveMethodType}
                        setFilterData={props.setFilterData}
                        onChartClick={handleChartClick}
                        checklistTemplateType={props.checklistTemplateType}
                    /> :
                    <PointChart chartData={chartState}
                                setFilterData={props.setChartData}
                                dataType={props.dataType} />}
            </Card>
        </>
    );
};

export default TabsReport;
