import React from "react";
import {GridItem} from "@consta/uikit/Grid";
import {Combobox} from "@consta/uikit/Combobox";
import {TextField} from "@consta/uikit/TextField";
import {ComboboxType} from "../../../core.component/mainInfo.constructor";
import {Text} from "@consta/uikit/Text";
import {useDispatch, useSelector} from "react-redux";
import {setValueMainInfo} from "../../../utils/features/checklistSlice";
import {
    selectValueMainInfo, selectViewMode
} from "../../../utils/features/selectors";


export interface QuestionDataSourceInterface {
    questionDataSource:
        {
            main_info: {
                [key: string]: {  // Добавляем сигнатуру индексации
                    data: any[],
                    group: any[]
                }
            },
            question: any[],
            bypass_info: {
                recipient: any[],
                watch_place: any[]
            }
        },
    mainMenuElements: ComboboxType[]
}

const RenderMainInfo: React.FC<QuestionDataSourceInterface> = (props) => {
    const dispatch = useDispatch();
    // const questionData = useSelector(selectQuestionData);
    const valueMainInfo = useSelector(selectValueMainInfo);

    const viewMode = useSelector(selectViewMode);

    const handleChange = (value: any, index: number) => {
        if (valueMainInfo) {
            const updatedValueMainInfo = [...valueMainInfo];
            // Добавляем поле 'model' в объект на основе result_name
            updatedValueMainInfo[index] = {
                ...value,
                model: props.mainMenuElements[index]?.result_name || '', // Привязываем модель
            };

            dispatch(setValueMainInfo(updatedValueMainInfo));
        }
    };


    return (
        <>
            {props.questionDataSource && props.mainMenuElements.map((el: ComboboxType, index: number) => {
                // Вызов хуков и расчет значений вне условных выражений
                const comboboxData =
                    viewMode !== "view"
                        ? props.questionDataSource.main_info[el.result_name]?.data
                        : el.data;

                const comboboxGroups =
                    viewMode !== "view"
                        ? props.questionDataSource?.main_info[el.result_name]?.group
                        : el.group;

                // Отрисовка элементов с условными выражениями
                return (
                    <React.Fragment key={`MainInfoData${index}`}>
                        <GridItem
                            col={6}
                            key={`mainInfoData-${index}`}
                            style={{paddingTop: "10px"}}
                        >
                            {el.type === "dict" ? (
                                viewMode !== "view" ? (
                                    <Combobox
                                        className="grid-item"
                                        placeholder={el.label}
                                        items={comboboxData || []}
                                        groups={comboboxGroups || []}
                                        size="m"
                                        view="default"
                                        form="default"
                                        labelPosition="top"
                                        status={!valueMainInfo[index] ? "alert" : "success"}
                                        value={valueMainInfo && valueMainInfo[index]}
                                        onChange={(value) => handleChange(value, index)}
                                        getItemKey={(item: any) => item.name}
                                        getItemLabel={(item: any) => item.name}
                                        getItemGroupKey={(item) => item.group}
                                        getGroupKey={(group: string) => group}
                                        getGroupLabel={(group: string) => group}
                                    />
                                ) : (
                                    <div style={{marginBottom: "10px"}}>
                                        <Text size="m" view="primary" weight="bold">
                                            {el.label}:
                                        </Text>
                                        <Text
                                            size="m">{valueMainInfo[index]?.group || "—"} {valueMainInfo[index]?.name || "-"}</Text>
                                    </div>
                                )
                            ) : viewMode !== "view" ? (
                                <TextField
                                    style={{width: "95%"}}
                                    onChange={(value) => handleChange(value, index)}
                                    value={valueMainInfo && valueMainInfo[index]}
                                    type="text"
                                    placeholder={el.label}
                                />
                            ) : (
                                <div style={{marginBottom: "10px"}}>
                                    <Text size="m" view="primary" weight="bold">
                                        {el.label}:
                                    </Text>
                                    <Text size="m">{valueMainInfo[index] || "—"}</Text>
                                </div>
                            )}
                        </GridItem>
                    </React.Fragment>
                );
            })}
        </>
    );
};

export default RenderMainInfo;
