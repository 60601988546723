import React, {useState} from "react";
import {Sidebar} from "@consta/uikit/Sidebar";
import {useDispatch, useSelector} from "react-redux";
import {setIsChecklistSidebarOpen, setNullModalOpen} from "../../utils/features/sidebarsOpenSlice";
import {fetchDeleteChecklist, fetchUpdateChecklist} from "../../utils/service/fetch.endpoint";
import ButtonGroupJournal from "./buttonGroupMolecule.journal";
import ButtonGroup from "../../molecules/new/buttonGroupMolecule.new";
import {AnswerType, clearChecklistState} from "../../utils/features/checklistSlice";
import {handleSaveButton} from "../../utils/helpers/handleSaveButton";
import ModalDraftOrganism from "../modalDraft";
import {mainInfoDataCheck} from "../../utils/helpers/mainInfoDataCheck";
import {buildMainInfoData} from "../../utils/helpers/buildMainInfoData";
import {BuildSaveData} from "../../utils/helpers/buildSaveData";
import {showSnackBar} from "../../utils/features/snackBarSlice";
import {
    selectChecklistID, selectCurrentTemplate, selectIsChecklistSidebarOpen, selectIsNullCountModalOpen,
    selectSelectedTemplate,
    selectUser,
    selectToBackendAnswerData, selectValueDate,
    selectValueMainInfo, selectViewMode
} from "../../utils/features/selectors";
import {ComboboxType} from "../../core.component/mainInfo.constructor";
import ChecklistForm from "../../pages/new/checklist.form";
import {setRefetchGrid} from "../../utils/features/gridSlice";
import {clearAnswerState} from "../../utils/features/answerSlice";

interface SidebarProps {
    isJournal: boolean;
}

const ChecklistSidebar: React.FC<SidebarProps> = ({
                                                      isJournal
                                                  }) => {
    const dispatch = useDispatch();
    const user = useSelector(selectUser);
    const selectedTemplate = useSelector(selectSelectedTemplate);
    const isNullCountModalOpen = useSelector(selectIsNullCountModalOpen);
    const toBackendAnswerData = useSelector(selectToBackendAnswerData);
    const valueMainInfo = useSelector(selectValueMainInfo);
    const valueDate = useSelector(selectValueDate);
    const viewMode = useSelector(selectViewMode);
    const checklistID = useSelector(selectChecklistID);
    const currentTemplate = useSelector(selectCurrentTemplate);
    const checklistSidebarOpen = useSelector(selectIsChecklistSidebarOpen);
    const [mainMenuElements, setMainMenuElements] = useState<ComboboxType[]>([])
    const [checklistData, setChecklistData] = useState<any>(undefined)
    const [valueAnswer, setValueAnswer] = useState<AnswerType[]>([])

    const handleCloseChecklistSideBar = () => {
        dispatch(clearChecklistState())
        dispatch(clearAnswerState());
        setValueAnswer([])
        dispatch(setIsChecklistSidebarOpen(false)); // Закрытие сайдбара

    };
    console.log(valueAnswer)
    const handleCancelButton = () => {
        if (viewMode === 'new') {
            fetchDeleteChecklist(checklistID);
        }
        dispatch(setRefetchGrid())
        dispatch(clearAnswerState())
        dispatch(clearChecklistState())
        setValueAnswer([])
        handleCloseChecklistSideBar();
    };

    const handleSavePressed = () => {
        handleSaveButton({
            checklistID: checklistID,
            viewMode: viewMode,
            valueAnswer: toBackendAnswerData,
            valueDate: valueDate,
            valueMainInfo: valueMainInfo,
            mainMenuElements: mainMenuElements,
            currentTemplate: currentTemplate,
            savedChecklistData: checklistData,
            dispatch: dispatch,
            username: user?.username
        })
    }

    // Функция для сохранения черновика
    const handleSaveDraft = () => {
        const mainInfo = buildMainInfoData({
            valueMainInfo,
            viewMode,
            currentTemplate,
            checklistData,
            dispatch,
            setMainMenuElements
        });
        const saveData = BuildSaveData({
            mainInfo,
            valueAnswer: toBackendAnswerData,
            valueMainInfo: valueMainInfo,
            checklistID: checklistID,
            currentTemplate: currentTemplate,
            checklistData,
            valueDate: valueDate
        });
        fetchUpdateChecklist({saveData, isDraft: true});
        dispatch(showSnackBar({
            messageText: 'Черновик сохранен!',
            status: 'success',
        }));
        dispatch(clearChecklistState())
        dispatch(setIsChecklistSidebarOpen(false));
    };


    return (
        <>
            <Sidebar
                isOpen={checklistSidebarOpen}
                onEsc={handleCloseChecklistSideBar}
                position="bottom"
                size="full"
            >
                <Sidebar.Content>
                    {selectedTemplate && (
                        <ChecklistForm setChecklistData={setChecklistData}
                                       mainMenuElements={mainMenuElements}
                                       setMainMenuElements={setMainMenuElements}
                                       valueAnswer={valueAnswer}
                                       setValueAnswer={setValueAnswer}
                        />
                    )}
                </Sidebar.Content>
                <Sidebar.Actions>
                    {isJournal ? <ButtonGroupJournal
                        checklistID={checklistID}
                        viewMode={viewMode}
                        setSavePressed={handleSavePressed}
                        handleCancelButton={handleCancelButton}
                    /> : <ButtonGroup
                        onCancel={handleCancelButton}
                        onSave={handleSavePressed}
                        onDraft={handleSaveDraft}
                        showSaveButton={viewMode !== 'view'}
                    />}
                </Sidebar.Actions>
            </Sidebar>
            <ModalDraftOrganism
                isOpen={isNullCountModalOpen}
                onClose={() => dispatch(setNullModalOpen())}
                onSaveDraft={handleSaveDraft}
                isMainInfoFilled={mainInfoDataCheck(valueDate, valueMainInfo)}
            />
        </>
    );
};

export default ChecklistSidebar;
