import React, {useState} from 'react';
import {useQuery} from "@tanstack/react-query";
import {axiosInstance} from "../../utils/service/axiosConfig";
import {API_DICT_TEMPLATE_GET} from "../../utils/service/api.endpoint";
import ChecklistSelector from "../../molecules/new/checklistSelectorMolecule.new";
import ChecklistSidebar from "../../organisms/sidebars/checklistSidebar";

const NewChecklistPage: React.FC = () => {
    const [teplateList, setTemplateList] =useState<any[]>([])

    // Fetch template data
    const {isPending, error, data, refetch} = useQuery({
        queryKey: ['template_list'],
        queryFn: () =>
            axiosInstance.get(API_DICT_TEMPLATE_GET).then(
                (res): { data: any[] } => {
                    setTemplateList(res.data);
                    return res.data;
                }
            ),
    });

    return (
        <div>
            {teplateList && <ChecklistSelector templateList={teplateList}/>}
            <ChecklistSidebar isJournal={false}/>

        </div>
    );
};

export default NewChecklistPage;
