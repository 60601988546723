import React, {useEffect} from "react";
import {User} from '@consta/uikit/User';
import {Notifications} from "@consta/header/Notifications";
import {fetchNotificationData} from "../../utils/service/fetch.endpoint";
import {useDispatch, useSelector} from "react-redux";
import {setNotifications} from "../../utils/features/notificationSlice";
import {selectNotifications, selectUser} from "../../utils/features/selectors";
// status: Array<'system' | 'warning'>;

type NOTIFICATIONS = {
    label: string;
    description: string;
    date: Date;
    badges?: any[];
    image?: string;
};


const UserComponent = ({name, info, onClick, refProp}: {
    name: string,
    info: string,
    onClick: () => void,
    refProp: any
}) => {
    const dispatch = useDispatch()
    const user = useSelector(selectUser)
    const notificationList = useSelector(selectNotifications)

    const notifications: NOTIFICATIONS[] = notificationList.map(item => ({
        label: item.watch_place,
        description: item.answer,
        date: new Date(item.date),
        image: item.photo_file,
        badges: [
            {
                label: `${item.org_name}`,
                status: 'normal'
            },
        ]
    }));

    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await fetchNotificationData();
                dispatch(setNotifications(result))
            } catch (error) {
                console.error('Ошибка при получении данных:', error);
            }
        };

        fetchData();
    }, []); // Зависимости могут быть добавлены, если нужно

    return (
        <>
            {user?.userRole === 'Инспектор' && notificationList.length > 0 &&
                <Notifications style={{color:'red'}} items={notifications} title="Уведомления" isMobile={true} groupByDay/>}
            <User
                size='l'
                name={name}
                info={info}
                status="available"
                onClick={onClick}
                withArrow={true}
                ref={refProp}
                style={{cursor: 'pointer', padding: '10px'}}
            />
        </>
    );
};

export default UserComponent;
