import React from "react";
import {Text} from "@consta/uikit/Text";
import {Grid, GridItem} from "@consta/uikit/Grid";
import {Divider} from "@mui/material";
import PhotoCarousel from "../../organisms/photoCarousel";
import {AnswerInterface} from "../../pages/responsible/page.remark";

interface RemarkDetailtProps {
    selectedAnswerData?: AnswerInterface
}

const RemarkDetail: React.FC<RemarkDetailtProps> = ({selectedAnswerData}) => {
    return (
        <Grid cols={3}>
            <GridItem col={3}>
                <Text className={"answer_detail_h1"} size="3xl" view="brand" weight="bold">
                    {selectedAnswerData?.watch_object} №{selectedAnswerData?.number}
                </Text>
            </GridItem>

            <GridItem col={2}>
                <Text className={"answer_detail_date"} size="s" weight="medium">
                    {selectedAnswerData?.date}
                </Text>
                <Text className={"answer_detail_answer"} size="l" weight="bold" >
                    {selectedAnswerData?.answer}
                </Text>
                <Text className={"answer_detail_recipient"} size="xs" weight="medium">
                    Ответственный: {selectedAnswerData?.recipient}
                </Text>
                <Text className={"answer_detail_recipient"} size="xs" weight="medium">
                    Составил: {selectedAnswerData?.author}
                </Text>

                {selectedAnswerData?.is_confirmed && (
                    <Text className={"answer_detail_h1"} size="s" view="success" weight="bold">
                        {selectedAnswerData?.updated_at}: Устранено, подтвердил {selectedAnswerData?.is_confirmed}
                    </Text>
                )}
            </GridItem>

            <GridItem col={1} row={2}>
                {selectedAnswerData && selectedAnswerData.photo?.length > 0 ? (
                    <div className={"answer_detail_photo"}>
                        <PhotoCarousel cameraPhotos={selectedAnswerData.photo}
                                       thumbnailPhotos={selectedAnswerData.thumbnails}
                                       canDelete={false}/>
                    </div>
                ) : (
                    <Text className={"answer_detail_no_photo"} weight="bold">
                        Фотоматериала нет
                    </Text>
                )}
            </GridItem>

            <GridItem col={3}>
                <Divider style={{padding: "10px"}}/>
            </GridItem>
        </Grid>
    );
};

export default RemarkDetail;
