import React from "react";
import {AnswerInterface} from "../page.remark";
import {Card} from "@consta/uikit/Card";
import {Text} from "@consta/uikit/Text";

interface AnswerCardInterface {
    dataAnswer: AnswerInterface[]
    otherAnswer?: AnswerInterface[]
    cardType: 'assigned' | 'decided'
    setFilterReportData: (filterReportData: AnswerInterface[] | undefined) => void
}

/**
 * Компонент AnswerCard
 *
 * Отображает карточку с центрированным, жирным, заглавным текстом. Цвет и размер текста определяются пропом `cardType`.
 *
 * @param {Object} props - Пропсы компонента.
 * @param {number} [props.dataAnswerLength=0] - Кол-во нарушений.
 * @param {'assigned' | 'decided'} props.cardType - Тип карточки (присвоеные нарушения и решенные)
 * @returns {JSX.Element} - Компонент AnswerCard.
 */
const AnswerCountCard = ({dataAnswer, otherAnswer, cardType, setFilterReportData}: AnswerCardInterface) => {
    const view: "success" | "alert" | 'primary' = cardType === 'assigned'
        ? dataAnswer?.length === 0 ? "success" : "alert"
        : "primary";

    return (
        <Card style={{minHeight: '150px', position: 'relative'}}>
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%'}}>
                <Text as="div"
                      align="center"
                      lineHeight="l"
                      cursor="pointer"
                      size="6xl"
                      spacing="m"
                      transform="uppercase"
                      view={view}
                      weight="bold"
                      style={{marginRight: '1rem'}}
                      onClick={() => setFilterReportData(dataAnswer)}>
                    {dataAnswer?.length}
                </Text>
            </div>
            {otherAnswer && otherAnswer?.length > 0 && (
                <div style={{position: 'absolute', bottom: '-0.5rem', right: '0.5rem'}}>
                    <Text size="4xl"
                          cursor="pointer"
                          view="warning"
                          onClick={() => setFilterReportData(otherAnswer)}>
                        <Text size='xs'>{cardType === 'assigned' ? 'не принято:' : 'на проверке:'}
                        </Text>{otherAnswer?.length}
                    </Text>
                </div>
            )}
            <div style={{position: 'absolute', top: '0.5rem', left: '0.5rem'}}>
                <Text size="xs" weight="bold">{cardType === 'assigned' ? 'В РАБОТЕ:' : 'УСТРАНЕНО:'}</Text>
            </div>
        </Card>
    );
}
export default AnswerCountCard