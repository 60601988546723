import React, { useState } from 'react';
import { DatePicker as ConstaDatePicker } from "@consta/uikit/DatePicker";

interface DatePickerProps {
    value: Date | null;
    onChange: (value: Date | null) => void;
    placeholder?: string;
}

const DatePickerAtom: React.FC<DatePickerProps> = ({ value, onChange, placeholder = 'Выберите дату' }) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <ConstaDatePicker
            className='grid-item'
            format="dd.MM.yyyy"
            type="date"
            status={!value ? 'alert' : 'success'}
            value={value}
            onChange={( value ) => onChange(value)}
            placeholder={placeholder}
            // disabled={isOpen} // Отключаем поле, когда открывается календарь
            onDropdownOpen={setIsOpen} // Отслеживаем состояние календаря
        />
    );
};

export default DatePickerAtom;
