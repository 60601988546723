import React from "react";
import {Grid, GridItem} from "@consta/uikit/Grid";
import {Text} from "@consta/uikit/Text";
import {Card} from "@consta/uikit/Card";
import PhotoCarousel from "../../organisms/photoCarousel";
import moment from "moment/moment";

import {AnswerInterface} from "../../pages/responsible/page.remark";

interface AnswerReportProps {
    selectedAnswerData: AnswerInterface
}
const AnswerReport: React.FC<AnswerReportProps> = (props) => {

    return (
        <Card style={{margin: '15px', padding: '5px'}}>
            <Grid cols={3}>
                <GridItem col={3}>
                    <Text className="answer_detail_h1" size="3xl" view="brand" weight="bold">
                        Отчет об устранении
                    </Text>
                </GridItem>

                <GridItem
                    col={3}
                    breakpoints={{
                        768: {
                            order: 0,
                            col: 1,
                        },
                    }}
                    style={{
                        maxHeight: '300px',
                        overflowY: 'auto',
                    }}
                >
                    {props.selectedAnswerData?.answer_comment.map((el, index) => {

                        return (
                            <div key={index} style={{marginBottom: "10px"}}>
                                <Card
                                    className="answer-card"
                                    style={{
                                        padding: "5px",
                                        margin: "15px",
                                        backgroundColor: index % 2 === 0 ? "#f1f1f1" : "#cde7f1",
                                    }}
                                >
                                    <Text size="xs" view="secondary">
                                        {moment(el.created_at).format("DD.MM.YYYY HH:MM")}
                                    </Text>
                                    <Text size="s" weight="bold">
                                        {el.user_name}
                                    </Text>
                                    <Text size="m">{el.comment_text}</Text>
                                </Card>
                            </div>
                        );
                    })}
                </GridItem>

                <GridItem
                    col={3}
                    row={1}
                    breakpoints={{
                        768: {
                            order: 0,
                            col: 2,
                            row: 1,
                        },
                    }}
                >
                    {props.selectedAnswerData && props.selectedAnswerData.decided_photo.length > 0 ? (
                        <div className="answer_detail_photo">
                            <PhotoCarousel
                                cameraPhotos={props.selectedAnswerData.decided_photo}
                                thumbnailPhotos={props.selectedAnswerData.decided_thumbnails}
                                canDelete={false}
                            />
                        </div>
                    ) : (
                        <Text className="answer_detail_no_photo" weight="bold">
                            Фотоматериала нет
                        </Text>
                    )}
                </GridItem>
            </Grid>
        </Card>
    );
};

export default AnswerReport;
