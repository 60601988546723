import React, {useRef, useState} from 'react';
import {Grid, GridItem} from "@consta/uikit/Grid";
import {Card} from "@consta/uikit/Card";
import {Text} from "@consta/uikit/Text";
import {Checkbox} from "@consta/uikit/Checkbox";
import {Button} from "@consta/uikit/Button";
import 'react-alice-carousel/lib/alice-carousel.css';
// @ts-ignore
import {v4 as uuidv4} from 'uuid';
import {
    fetchAnswerData,
    fetchDeleteAnswer,
    fetchSaveNullAnswer,
} from "../../../utils/service/fetch.endpoint";
import {IconTrash} from '@consta/icons/IconTrash';
import {BYPASS_TEMPLATE_QUESTION_ID} from "../../../utils/service/config";
import {ComboboxType, TemplateListDataType, ViewModeType} from "../../../types/new.type";
import {renderField} from "../../../utils/helpers/renderField";
import PhotoCollapse from "../../../organisms/photoCollapse";
import {Badge} from '@consta/uikit/Badge';
import {useQuery} from "@tanstack/react-query";
import {useDispatch, useSelector} from "react-redux";
import {AnswerType} from "../../../utils/features/checklistSlice";
import {
    selectUrlPath,
    selectViewMode
} from "../../../utils/features/selectors";
import AnswerSidebarTemplate from "../../../organisms/sidebars/answerSidebarTemplate";
import {setIsAnswerSidebarOpen} from "../../../utils/features/sidebarsOpenSlice";
import {setIsDecide, setSelectedAnswerData} from "../../../utils/features/answerSlice";

export type BypassType = {
    id: string,
    answer: string,
    number: string,
    recipient: ComboboxType,
    watch_object: ComboboxType,
    is_karkas: boolean,
    photo?: string[],
    thumbnail?: string[],
}

interface RenderBypassListProps {
    questionData: TemplateListDataType;
    checklistID: string;
    valueAnswer: any;
    setValueAnswer: (value: any) => void;
}

export default function RenderBypassList(props: RenderBypassListProps) {
    const dispatch = useDispatch();
    const urlPath = useSelector(selectUrlPath);
    const viewMode = useSelector(selectViewMode);

    const [selectedAnswerId, setSelectedAnswerId] = useState<string | null>(null)
    const [answerIndex, setAnswerIndex] = useState<number>()
    const containerRef = useRef<HTMLDivElement>(null);

    const {data: fetchedAnswerData, refetch} = useQuery({
        queryKey: ['answerData'],
        queryFn: async () => {
            const fetchAnswer = await fetchAnswerData({answerID: selectedAnswerId ?? ''})
            dispatch(setSelectedAnswerData(fetchAnswer))
            setSelectedAnswerId(null)
            return fetchAnswerData
        },
        enabled: selectedAnswerId !== null
    });

    const handleChangeBypass = (value: any, index: number, fieldName: string) => {
        const updatedValueBypassData = [...props.valueAnswer];
        updatedValueBypassData[index] = {
            ...updatedValueBypassData[index],
            [fieldName]: value,
        };
        props.setValueAnswer(updatedValueBypassData)

    };

    const handleCloseSideBar = () => {
        dispatch(setIsAnswerSidebarOpen())
        dispatch(setIsDecide(false))
    }
    const handleOnCheck = (answerID: string, answerIndex: number) => {
        setSelectedAnswerId(answerID)
        setAnswerIndex(answerIndex)
        dispatch(setIsAnswerSidebarOpen())
    }

    const handleScrollToBottom = () => {
        // todo не работает скролл при добавлении элемента
        if (containerRef.current) {
            containerRef.current.scrollIntoView({behavior: 'smooth', block: 'end'});
        }
    };

    const addItem = async () => {
        const answerID = uuidv4();
        try {
            await fetchSaveNullAnswer({draftAnswerID: answerID, checklistID: props.checklistID});
            const newItem = {
                answer_id: answerID,
                id: BYPASS_TEMPLATE_QUESTION_ID,
                number: '',
                answer: '',
                recipient: {} as ComboboxType,
                watch_object: {} as ComboboxType,
                is_karkas: false,
            };
            const updatedBypassData = [...props.valueAnswer, newItem];
            props.setValueAnswer(updatedBypassData);
            handleScrollToBottom(); // Прокрутка вниз после добавления
        } catch (error) {

            console.error("Error in addItem:", error);
        }
    };

    const removeItem = (index: number) => {
        const answerID = props.valueAnswer[index].answer_id || props.valueAnswer[index].id;
        fetchDeleteAnswer({answerID})
            .then(() => {
                const updatedData = props.valueAnswer.filter((_: any, i: number) => i !== index);
                // dispatch(setValueAnswer(updatedData));
                props.setValueAnswer(updatedData)
            })
            .catch((error) => {
                console.error("Error in removeItem:", error);
            });
    };

    const endOfListRef = useRef<HTMLDivElement | null>(null);


    const renderStatusBadge = (el: AnswerType, index: number, viewMode: ViewModeType) => {
        if (viewMode !== 'view') {
            return <Text>{`№ ${index + 1}`}</Text>;
        }

        if (el.is_confirmed) {
            return <Text>{`№ ${index + 1}`} <Badge status="normal" label={el.assigned_name}/>
                <Badge status="success" label="Выполнено"
                       onClick={() => handleOnCheck(el.id, index)}/></Text>;
        } else if (el.assigned_name && !el.is_returned && !el.is_confirmed && !el.is_decided) {
            return <Text>
                {`№ ${index + 1}`} <Badge status="normal" label={el.assigned_name}/>
                <Badge status="warning" label='В работе'/>
            </Text>;
        } else if (el.assigned_name && el.is_returned && !el.is_confirmed) {
            return <Text>{`№ ${index + 1}`} <Badge status="normal" label={el.assigned_name}/>
                <Badge status="error" label='Отклонено'
                       onClick={() => handleOnCheck(el.id, index)}/></Text>;
        } else if (el.assigned_name && el.is_decided && !el.is_confirmed) {
            return <Text>{`№ ${index + 1}`} <Badge status="normal" label={el.assigned_name}/>
                <Badge status="normal" label='На проверке'
                       onClick={() => handleOnCheck(el.id, index)}/></Text>;
        } else if (!el.is_decided && !el.assigned_name && !el.is_returned) {
            return <Text>{`№ ${index + 1}`} <Badge status="error" label='Не распределено'/></Text>;
        }
    };

    console.log()
    return (
        <>
            {props.valueAnswer?.map((el: AnswerType, index: number) => {
                console.log(el.watch_object)
                return (
                    <React.Fragment key={el.answer_id || `bypass${index}`}>
                        <GridItem col={12}>
                            <Card className='card-view'>
                                <Grid cols={12}>
                                    <GridItem col={11}>
                                        {renderStatusBadge(el, index, viewMode)}
                                    </GridItem>
                                    <GridItem col={1}>
                                        {viewMode !== 'view' && (
                                            <IconTrash onClick={() => removeItem(index)} view={'link'}/>
                                        )}
                                    </GridItem>

                                    <GridItem col={6} style={{paddingRight: '10px'}}>
                                        {renderField(
                                            'combobox',
                                            'Объект осмотра',
                                            el.watch_object,
                                            'Объект осмотра',
                                            Object.keys(el.watch_object).length > 0 ? 'success' : 'alert',
                                            index,
                                            handleChangeBypass,
                                            props.questionData?.bypass_info?.watch_object || [],  // массив объектов для выбора
                                            'watch_object',
                                            viewMode
                                        )}
                                    </GridItem>

                                    <GridItem col={4}>
                                        {renderField(
                                            'textfield',
                                            '№',
                                            viewMode !== 'view' ? undefined : el.number,
                                            '№',
                                            !el.number ? 'alert' : 'success',
                                            index,
                                            handleChangeBypass,
                                            undefined,
                                            'number',
                                            viewMode
                                        )}
                                    </GridItem>

                                    <GridItem col={2}>
                                        <div className="checkbox-container">
                                            <Text className="checkbox-text">КБ</Text>
                                            <Checkbox
                                                className='kb-checkbox'
                                                size="l"
                                                view="primary"
                                                checked={el.is_karkas}
                                                align="top"
                                                disabled={viewMode === 'view'}
                                                onChange={(e) => handleChangeBypass(e.target.checked, index, 'is_karkas')}
                                            />
                                        </div>
                                    </GridItem>

                                    <GridItem col={viewMode !== 'view' ? 12 : 6} style={{paddingTop: '10px'}}>
                                        {renderField(
                                            'combobox',
                                            'Ответственный',
                                            el.recipient,
                                            'Ответственный',
                                            Object.keys(el.recipient).length > 0  ? 'success' : 'alert',
                                            index,
                                            handleChangeBypass,
                                            props.questionData.bypass_info?.recipient || [],
                                            'recipient',
                                            viewMode
                                        )}
                                    </GridItem>

                                    <GridItem col={12} style={{paddingTop: '10px'}}>
                                        {renderField(
                                            'textarea',
                                            'Предписание',
                                            viewMode !== 'view' ? undefined : el.answer,
                                            'Предписание',
                                            !el.answer ? 'alert' : 'success',
                                            index,
                                            handleChangeBypass,
                                            undefined,
                                            'answer',
                                            viewMode
                                        )}
                                    </GridItem>

                                    <GridItem col={12} style={{backgroundColor: 'lightgrey', marginTop: '10px'}}>
                                        <PhotoCollapse
                                            index={index}
                                            answerID={urlPath === '/draft-journal' ? el.id : el.answer_id}
                                            photo={el.photo ?? []}
                                            thumbnail={el.thumbnail ?? []}
                                            saveMethod={'new'}
                                            canDelete={viewMode !== 'view'}
                                            viewMode={viewMode}
                                        />
                                    </GridItem>
                                </Grid>
                            </Card>
                        </GridItem>

                    </React.Fragment>
                );
            })}
            <AnswerSidebarTemplate
                handleCloseSideBar={handleCloseSideBar}
                checklistTemplateType={"bypass"}
                viewType={"report"}
                index={answerIndex}
                valueAnswer={props.valueAnswer}
                setValueAnswer={props.setValueAnswer}
            />
            {viewMode !== 'view' && (
                <>
                    {props.valueAnswer?.length === 0 && (
                        <GridItem col={12}>
                            <Text view="system">Заполните основную информацию и добавьте объект осмотра</Text>
                        </GridItem>
                    )}

                    <GridItem col={9}>
                        <Button view="secondary" label="+ объект" onClick={addItem}/>
                    </GridItem>
                </>
            )}
            <div ref={endOfListRef}></div>
        </>
    )
}
