import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import {AgGridReact} from '@ag-grid-community/react';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-alpine.css";
import "../../pages/report/component/css/grid.report.css";
import {CellDoubleClickedEvent} from "@ag-grid-community/core";
import {CellClickedEvent} from '@ag-grid-community/core';
import {ChecklistTemplateType} from "../../utils/service/fetch.endpoint";
import {AnswerInterface, viewTypeType} from "../../pages/responsible/page.remark";
import {
    colDef,
    columnDefsInspectorBypass, columnDefsInspectorKB,
    getRowStyle,
    gridContext,
    rowStyle,
    statusPanel
} from "../../pages/report/component/helpers/grid.report.settings";
import {customEngLocale} from "../../core.component/gridLocale";
import {useDispatch} from "react-redux";
import AnswerSidebarTemplate from "../sidebars/answerSidebarTemplate";
import {setIsAnswerSidebarOpen} from "../../utils/features/sidebarsOpenSlice";
import {
    clearAnswerState,
    setSelectedAnswerData as reduxSetSelectedAnswerData
} from "../../utils/features/answerSlice";
import {clearChecklistState} from "../../utils/features/checklistSlice";

interface GridReportInterface {
    filterData: any
    viewType: viewTypeType
    checklistTemplateType: ChecklistTemplateType
    setRefetchData?: (value: boolean) => void;
}

const GridReport = (props: GridReportInterface) => {
    const dispatch = useDispatch();

    const gridRef = useRef<any>();
    const [rowData, setRowData] = useState<any>();
    const [isLoading, setIsLoading] = useState(false);
    const [divHeight, setDivHeight] = useState(0);
    const [selectedAnswerData, setSelectedAnswerData] = useState<AnswerInterface>();

    // контекстное меню
    const getContextMenuItems = useCallback((params: any) => {
        return gridContext()
    }, []);

    const gridOptions = {
        columnDefs: columnDefsInspectorBypass,
        defaultColDef: {
            sortable: true,
            resizable: true,
        },
        localeText: customEngLocale, // Ваш кастомный перевод, если требуется
    };

    const onCellDoubleClicked = (params: CellDoubleClickedEvent) => {
        console.log(params.colDef.field)
        console.log(params.node)
    }

    const getRowId = useMemo(() => {
        return (params: any) => {
            return `${params.data.checklist_id}-${params.data.answer_id} `
        };
    }, []);

    const defaultColDef = useMemo(() => {
        return colDef
    }, []);


    useEffect(() => {
        dispatch(reduxSetSelectedAnswerData(selectedAnswerData))
    }, [selectedAnswerData]);

    const onGridReady = async () => {
        setRowData(props.filterData)
        console.log(props.filterData)
    };

    const onCellClicked = (event: CellClickedEvent) => {
        if (event.event?.type === 'click') {
            dispatch(setIsAnswerSidebarOpen())
            setSelectedAnswerData(event.data)
        }
    };

    const statusBar = useMemo(() => {
        return statusPanel
    }, []);


    const handleCloseSideBar = () => {
        dispatch(setIsAnswerSidebarOpen())
        dispatch(clearAnswerState())
        dispatch(clearChecklistState())
        setSelectedAnswerData(undefined)
        props.setRefetchData && props.setRefetchData(true)
    }


    useEffect(() => {
        onGridReady()
    }, [props.filterData]);


    // resize grid to bottom
    useEffect(() => {
        const handleResize = () => {
            const viewportHeight = window.innerHeight;
            // @ts-ignore
            const elementOffsetTop = document.querySelector('.ag-theme-alpine').offsetTop;
            const calculatedHeight = viewportHeight - elementOffsetTop;
            setDivHeight(calculatedHeight);
        };

        handleResize();
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <React.Fragment>
            <div className='ag-theme-alpine grid-report' style={{height: 'calc(100vh - 280px)'}}>
                {rowData && isLoading ? "LOADING" :
                    <AgGridReact
                        gridOptions={gridOptions}
                        rowStyle={rowStyle}
                        getRowStyle={getRowStyle}
                        ref={gridRef}
                        rowData={rowData}
                        columnDefs={columnDefsInspectorBypass}
                        animateRows={true}
                        getRowId={getRowId}
                        defaultColDef={defaultColDef}
                        onGridReady={onGridReady}
                        statusBar={statusBar}
                        onCellDoubleClicked={onCellDoubleClicked}
                        allowContextMenuWithControlKey={true}
                        getContextMenuItems={getContextMenuItems}
                        onCellClicked={onCellClicked}
                        rowGroupPanelShow={'always'}
                    />
                }
            </div>
            {selectedAnswerData &&
                <AnswerSidebarTemplate
                    handleCloseSideBar={handleCloseSideBar}
                    checklistTemplateType={props.checklistTemplateType}
                    viewType={props.viewType}
                />}
        </React.Fragment>
    );
};

export default GridReport;