import React from 'react';
import {useForm} from 'react-hook-form';

import '../../styles/login.css'
import FormField from "./formField.molecule";
import ButtonLogin from "./button.atom";


interface LoginFormInputs {
    username: string;
    password: string;
}

interface LoginFormProps {
    onSubmit: (data: LoginFormInputs) => void;
    errors: any;
}

const LoginForm: React.FC<LoginFormProps> = ({onSubmit, errors}) => {
    const {register, handleSubmit} = useForm<LoginFormInputs>();

    return (
        <form onSubmit={handleSubmit(onSubmit)} className="login-form">
            <FormField
                id="username"
                label="Имя пользователя"
                type="text"
                placeholder="Имя пользователя"
                register={register}
                error={errors.username?.message}
            />
            <FormField
                id="password"
                label="Пароль"
                type="password"
                placeholder="Пароль"
                register={register}
                error={errors.password?.message}
            />
            <ButtonLogin text='Войти'/>
        </form>
    );
};

export default LoginForm;
