export const CheckedNullCountAnswer = (currentChecklistData: any) => {
    let nullCount = 0;

    for (let i = 0; i < currentChecklistData.length; i++) {
        const cur = currentChecklistData[i];

        // Извлекаем только нужные поля
        const { answer, number, recipient, watch_object } = cur;
        const checkedNullObject = { answer, number, recipient, watch_object };

        // Получаем значения только из этих полей
        const values = Object.values(checkedNullObject);

        // Проверяем на null, undefined, пустую строку или пустые объекты
        const cnt = values.filter((val: any) =>
            val === null || val === undefined || val === "" ||
            (typeof val === 'object' && !Array.isArray(val) && Object.keys(val).length === 0)
        ).length;

        nullCount += cnt;
    }

    return nullCount;
}
