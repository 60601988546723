import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SnackBarItemStatus } from "@consta/uikit/SnackBar";

// Тип для хранения состояния SnackBar
interface SnackBarState {
    showSnackBar: boolean;
    messageText: string;
    status: SnackBarItemStatus;
    progressMode?: "line" | "timer";
}

// Начальное состояние
const initialState: SnackBarState = {
    showSnackBar: false,
    messageText: "",
    status: "normal",
    progressMode: "timer",
};

// Создаем слайс для SnackBar
const snackBarSlice = createSlice({
    name: "snackBar",
    initialState,
    reducers: {
        showSnackBar: (
            state,
            action: PayloadAction<{ messageText: string; status?: SnackBarItemStatus; progressMode?: "line" | "timer" }>
        ) => {
            state.showSnackBar = true;
            state.messageText = action.payload.messageText;
            state.status = action.payload.status || "normal";
            state.progressMode = action.payload.progressMode || "timer";
        },
        hideSnackBar: (state) => {
            state.showSnackBar = false;
            state.messageText = "";
        },
    },
});

// Экспортируем экшены
export const { showSnackBar, hideSnackBar } = snackBarSlice.actions;

// Экспортируем редьюсер для подключения к store
export default snackBarSlice.reducer;
