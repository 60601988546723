import {createSlice, PayloadAction} from '@reduxjs/toolkit';

export interface AnswerType {
    id: string;
    answer: string;
    answer_id: string;
    comment: string | null;
    number: string;
    recipient: {
        id: number;
        name: string;
    };
    watch_object: {
        id: number;
        name: string;
    };
    photo: any[];
    thumbnail: any[];
    assigned_id: string | null;
    assigned_name?: string;
    is_karkas?: boolean;
    is_decided?: boolean;
    decided_date?: string | null;
    is_returned?: boolean;
    is_confirmed?: boolean;
}


interface ToBackendState {
    valueAnswerData: AnswerType[];
}

const initialState: ToBackendState = {
    valueAnswerData: [],
};


const toBackendSlice = createSlice({
    name: 'toBackend',
    initialState,
    reducers: {
        setAnswerData(state, action: PayloadAction<any[]>) {
            state.valueAnswerData = action.payload;
        },
        clearToBackendState: (state) => {
            state.valueAnswerData = [];
        },

    },
});

export const {
    setAnswerData,
} = toBackendSlice.actions;

export default toBackendSlice.reducer;
